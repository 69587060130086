import { Skeleton } from "antd";
import { useParams } from "react-router-dom";

import { PageError } from "../../components/PageError";
import { useQuery } from "../../hooks/useQuery";
import { useN8n2 } from "../../hooks/useN8n2";
import { usePgrst } from "../../hooks/usePgrst";
import { render } from "../render";

function strToFn(fn) {
  switch (fn) {
    case "useN8n2":
      return useN8n2;
    case "usePgrst":
    default:
      return usePgrst;
  }
}

export function Screen({ source, dataFn, params = {}, deps = [], vars = {} }) {
  const query = useQuery();

  const { loading, error, data = {}, refetch } = strToFn(dataFn)(source, params, [...deps, source]);

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  return render(data, {
    vars: {
      query,
      refetch,
      ...vars,
    },
  });
}

export function SimpleScreen({ source, vars = {} }) {
  return <Screen source={source} vars={vars} />;
}

export function CardScreen({ source, dataFn, idKey = "id", vars = {} }) {
  const { id = null } = useParams();

  return <Screen dataFn={dataFn} source={source} params={{ [idKey]: id }} deps={[id]} vars={{ id, ...vars }} />;
}

export function TabScreen({ source }) {
  const { tab } = useParams();

  return <Screen source={source} vars={{ tab }} />;
}

export function TabCardScreen({ source }) {
  const { id, tab } = useParams();

  return <Screen source={source} params={{ id }} deps={[id]} vars={{ id, tab }} />;
}

export function QueryScreen({ dataFn, source, queryMap }) {
  const query = useQuery();

  const qqq = Object.fromEntries(
    Object.entries(queryMap || {}).map((i) => {
      const v = query.get(i[0]);
      switch (i[1]) {
        case "integer":
          return [i[0], parseInt(v)];
        default:
          return [i[0], v];
      }
    })
  );

  return (
    <Screen
      dataFn={dataFn}
      source={source}
      params={qqq}
      deps={Object.values(qqq)}
      vars={{
        query,
      }}
    />
  );
}
