import _ from "lodash";

import { apiPath } from "../constants";
import { fetchWith401, fetchWithJsonRpc } from "./helpers";

export const handleSave = function (history, res, id, omit, merge, body = null) {
  return (e, succ) => {
    fetchWith401(
      `${apiPath}/${res}?select=id&on_conflict=id`,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Prefer: ["resolution=merge-duplicates", "return=representation"],
          Accept: "application/vnd.pgrst.object+json",
        },

        body: JSON.stringify(body === null ? [_.mergeWith(_.omit(e, omit), merge)] : body(e)),
      },
      (result) => {
        succ && succ(result);
      },
      history
    );
  };
};

export const handleSaveN8n2 = function (res, merge = {}, body, version = "v1") {
  return handleSaveN8n(res, merge, body, version, 2);
};

export const handleSaveN8n = function (res, merge = {}, body, version = "v1", n8nV = 1) {
  return (s, succ) => {
    const b = typeof body === "function" ? body(s) : s;
    const params = _.mergeWith(b, merge);
    const action = params.id ? "update" : "create";
    return fetchWithJsonRpc(`admin.${res}.${action}.${version}`, params, {}, n8nV).then((result) => {
      succ && succ(result);
    });
  };
};

export const handleDelete = function (history, res) {
  return (e) => {
    if (_.get(e, "id")) {
      fetchWith401(
        `${apiPath}/${res}?id=eq.${e.id}`,
        {
          method: "DELETE",
        },
        () => {},
        history
      );
    }
  };
};

export const handleDeleteN8n2 = function (res, merge = {}, version = "v1") {
  return handleDeleteN8n(res, merge, version, 2);
};

export const handleDeleteN8n = function (res, merge = {}, version = "v1", n8nV = 1) {
  return (e) => {
    const id = _.get(e, "id");
    if (!id) {
      return Promise.resolve({});
    }
    const params = _.mergeWith(e, merge);
    return fetchWithJsonRpc(`admin.${res}.delete.${version}`, params, {}, n8nV);
  };
};
