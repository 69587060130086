import { useContext } from "react";
import { Skeleton } from "antd";
import _ from "lodash";

import { MyTabs } from "../../../components/Tabs";
import { useN8n2 } from "../../../hooks/useN8n2";
import { bindFunction } from "../../helpers";
import { JHTMLContext } from "../JHTMLContext";
import { PageError } from "../../../components/PageError";

function AsyncTab({ source, params = {} }) {
  const { render, options } = useContext(JHTMLContext);
  const { loading, error, data = {} } = useN8n2(source, params);

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <PageError error={error} />;
  }

  return render(data, options);
}

function SyncTab({ children }) {
  const { render, options } = useContext(JHTMLContext);

  return render(children, options);
}

export function AsyncTabs({ items, ...props }) {
  const { options } = useContext(JHTMLContext);
  const { vars } = options || {};

  const _props = {
    ...props,
    resolvePath: bindFunction(props.resolvePath, vars, ["key"]),
  };

  return (
    <MyTabs
      {..._props}
      items={items.map(({ async = true, ...item }) => {
        return {
          ...item,
          children: async ? <AsyncTab {...item} params={_.merge({ tab: item.key }, item.params)} /> : <SyncTab children={item.children} />,
        };
      })}
    />
  );
}
