import { Button as AntdButton } from "antd";
import * as AntdIcons from "@ant-design/icons";

export { Alert } from "./Alert";
export { ArrayMap } from "./ArrayMap";
export { Dropdown } from "./Dropdown";
export { AsyncSelect, Select } from "./Select";
export { Switch } from "./Switch";
export { AsyncTabs } from "./Tabs";
export { EditableTable, SortableTable, Table } from "./Table";
export { IF } from "./IF";
export { InitVar } from "./InitVar";
export { Form, FormItem, UseForm } from "./Form";
export { ForwardProps } from "./ForwardProps";
export { Modal } from "./Modal";
export { ReactUseState } from "./ReactUseState";

export function Fragment({ children }) {
  return <>{children}</>;
}

export function Div(props) {
  return <div {...props} />;
}

export function UnorderedList(props) {
  return <ul {...props} />;
}

export function OrderedList(props) {
  return <ol {...props} />;
}

export function ListItem(props) {
  return <li {...props} />;
}

export function Span(props) {
  return <span {...props} />;
}

export function Icon({ name, ...props }) {
  const Icon = AntdIcons[name];

  return <Icon {...props} />;
}

export function Button({ icon, ...props }) {
  let _icon = null;

  if (icon) {
    const Icon = AntdIcons[icon];
    _icon = <Icon />;
  }

  return <AntdButton icon={_icon} {...props} />;
}

export function ReactHTML({ value, style }) {
  return <div style={style} dangerouslySetInnerHTML={{ __html: value }} />;
}
