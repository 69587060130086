import React, { useState } from "react";
import { Form, Input, Modal, Typography } from "antd";

import { AsyncRadioGroup, AsyncSelect2 } from "../components/FormInput";
import { useN8n2Defer } from "../hooks/useN8n2Defer";
import { useForm } from "antd/lib/form/Form";

const { Text, Paragraph } = Typography;

function useOrderUpdate() {
  return useN8n2Defer("admin.order.update.v1");
}

export function OrderComment({ initValue = "", id }) {
  const [form] = useForm();
  const [value, setValue] = useState(initValue);
  const [isEdit, setIsEdit] = useState(false);

  const update = useOrderUpdate();

  function onSubmit({ comment }) {
    update({ id, comment }).then(() => {
      setValue(comment);
      setIsEdit(false);
    });
  }

  if (isEdit) {
    return (
      <Form form={form} initialValues={{ comment: value }} layout="vertical" onFinish={onSubmit}>
        <Form.Item label="" name="comment" style={{ marginBottom: 10 }}>
          <Input.TextArea
            name="comment"
            rows={3}
            onBlur={() => {
              form.submit();
            }}
          />
        </Form.Item>
      </Form>
    );
  }

  if (value) {
    return (
      <Paragraph
        type="secondary"
        style={{ fontSize: "90%", fontStyle: "italic", cursor: "pointer", whiteSpace: "break-spaces" }}
        onClick={() => setIsEdit(true)}
      >
        {value}
      </Paragraph>
    );
  }

  return (
    <Paragraph type="secondary" underline style={{ fontSize: "90%", cursor: "pointer" }} onClick={() => setIsEdit(true)}>
      Добавить комментарий...
    </Paragraph>
  );
}

export function OrderWorkStatus({ initValue, id }) {
  const [value, setValue] = useState(initValue);
  const [newValue, setNewValue] = useState();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const update = useOrderUpdate();

  function onChange(e) {
    setNewValue(e.target.value);
    setVisible(true);
  }

  function onConfirm() {
    setConfirmLoading(true);

    update({ id, work_status: newValue })
      .then(() => {
        setValue(newValue);
        setVisible(false);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  }

  function onCancel() {
    setValue(value);
    setVisible(false);
  }

  return (
    <>
      <AsyncRadioGroup jrpcMethod="admin.order_work_status.list.v1" title="title" value={value} onChange={onChange} />
      <Modal
        title="Подтверждение действия"
        okText="Да"
        cancelText="Отмена"
        onOk={onConfirm}
        onCancel={onCancel}
        visible={visible}
        confirmLoading={confirmLoading}
      >
        <Text>Вы желаете изменить статус заказа?</Text>
      </Modal>
    </>
  );
}

export function OrderManager({ initValue = null, id }) {
  const [value, setValue] = useState(initValue);
  const [newValue, setNewValue] = useState();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const update = useOrderUpdate();

  function onChange(e) {
    setNewValue(e);
    setVisible(true);
  }

  function onConfirm() {
    setConfirmLoading(true);

    const params = { id };
    if (newValue) {
      params.id_manager = newValue;
    } else {
      params.no_namager = true;
    }

    update(params)
      .then(() => {
        setValue(newValue);
        setVisible(false);
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  }

  function onCancel() {
    setValue(value || null);
    setVisible(false);
  }

  return (
    <>
      <AsyncSelect2
        placeholder="Без менеджера"
        jrpcMethod="admin.manager.list.v1"
        title="name"
        style={{ width: 360 }}
        n8nVersion={2}
        value={value}
        onChange={onChange}
        allowClear={true}
      />
      <Modal
        title="Подтверждение действия"
        okText="Да"
        cancelText="Отмена"
        onOk={onConfirm}
        onCancel={onCancel}
        visible={visible}
        confirmLoading={confirmLoading}
      >
        <Text>Вы желаете изменить менеджера заказа?</Text>
      </Modal>
    </>
  );
}
