import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Breadcrumb,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Typography,
  notification,
} from "antd";
import { DeleteOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons";

import {
  DateCellRenderer,
  TrainingCellRenderer,
  ScenarioCellRenderer,
  OfferPriceCellRenderer,
  DynamicStreamRuleRenderer,
} from "../components/CellRenderer";
import { HTML2Unicode } from "../components/HTML2Unicode";
import { CardButtons, CardDescriptions, CardHeader } from "../components/Card";
import { MyTabs } from "../components/Tabs";
import { PageError } from "../components/PageError";
import { InputDate } from "../components/FormInput";

import { DateFormats } from "../constants";
import { showFailureSaveMessage, showSuccessSaveMessage } from "../helpers/notification";
import { useN8n2 } from "../hooks/useN8n2";
import { useN8n2Defer } from "../hooks/useN8n2Defer";
import { useQuery } from "../hooks/useQuery";
import { useManyJsonRpc } from "../hooks/useManyJsonRpc";
import { history } from "../history";

const { Text } = Typography;

export const STREAM_TYPE_STATIC = "stream";
export const STREAM_TYPE_DYNAMIC = "dynamic_stream";
const FIELD_TITLE = "title";
const FIELD_TRAINING = "training";
const FIELD_PRE_STARTED_AT_RULE = "pre_started_at_rule";
const FIELD_STARTED_AT_RULE = "started_at_rule";
const FIELD_ENDED_AT_RULE = "ended_at_rule";
const FIELD_PRE_STARTED_AT_N = "pre_started_at_n";
const FIELD_STARTED_AT_N = "started_at_n";
const FIELD_ENDED_AT_N = "ended_at_n";
const FIELD_PRE_STARTED_AT = "pre_started_at";
const FIELD_STARTED_AT = "started_at";
const FIELD_ENDED_AT = "ended_at";

function getStreamTypePathPrefix(type) {
  return type === STREAM_TYPE_DYNAMIC ? "/dynamic" : "";
}

function getStreamCardRoutePath(id_training, id, type, tab) {
  return `/trainings/${id_training}/streams${getStreamTypePathPrefix(type)}/${id}${tab ? `/${tab}` : ""}`;
}

function getStreamEditRoutePath(id_training, id, type) {
  return getStreamCardRoutePath(id_training, id, type, "edit");
}

function getStreamCreateRoutePath(id_training, type) {
  return getStreamCardRoutePath(id_training, "create", type);
}

function shouldShowNField(value) {
  return ("" + value).startsWith("n_");
}

function getStreamRuleRenderer(type, rules, date_field, rule_field, n_field) {
  return (value, record) => {
    if (type === STREAM_TYPE_DYNAMIC) {
      return <DynamicStreamRuleRenderer value={_.get(record, rule_field)} n={_.get(record, n_field)} rules={rules} />;
    }

    return <DateCellRenderer value={_.get(record, date_field)} />;
  };
}

function StreamTypeSwitcher({ value, onChange }) {
  return (
    <Form.Item>
      <Radio.Group
        onChange={onChange}
        value={value}
        optionType="button"
        options={[
          { label: "Статичный поток", value: STREAM_TYPE_STATIC },
          { label: "Динамический поток", value: STREAM_TYPE_DYNAMIC },
        ]}
      />
    </Form.Item>
  );
}

function StreamFormTypeSwitcher({ value }) {
  const { id_training } = useParams();

  const onChange = (e) => {
    history.push(getStreamCreateRoutePath(id_training, e.target.value));
  };

  return <StreamTypeSwitcher value={value} onChange={onChange} />;
}

function StreamBaseForm({ type, ...props }) {
  if (type === STREAM_TYPE_DYNAMIC) {
    return <DynamicStreamForm {...props} />;
  }

  return <StaticStreamForm {...props} />;
}

function StaticStreamForm({ data, submitForm, mode }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      {mode !== "edit" && <StreamFormTypeSwitcher value={STREAM_TYPE_STATIC} />}

      <Form.Item label="Название" name={FIELD_TITLE} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Пред-старт" name={FIELD_PRE_STARTED_AT} rules={[{ required: true }]}>
        <InputDate />
      </Form.Item>
      <Form.Item label="Старт" name={FIELD_STARTED_AT} rules={[{ required: true }]}>
        <InputDate />
      </Form.Item>
      <Form.Item label="Финиш" name={FIELD_ENDED_AT} rules={[{ required: true }]}>
        <InputDate />
      </Form.Item>

      <Divider />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

function DynamicStreamForm({ data, submitForm, mode }) {
  const { loading, data: options, error } = useN8n2("admin.selector.dynamic_stream_rule.v1");
  const calcExample = useN8n2Defer("admin.dynamic_stream.calc.v1");

  const [shouldShowN, setShouldShowN] = useState({
    [FIELD_PRE_STARTED_AT_RULE]: shouldShowNField(data[FIELD_PRE_STARTED_AT_RULE]),
    [FIELD_STARTED_AT_RULE]: shouldShowNField(data[FIELD_STARTED_AT_RULE]),
    [FIELD_ENDED_AT_RULE]: shouldShowNField(data[FIELD_ENDED_AT_RULE]),
  });

  function onSelectChange(field) {
    return (e) => {
      setCalcResult({});
      setShouldShowN({
        ...shouldShowN,
        [field]: shouldShowNField(e),
      });
    };
  }

  const [form] = Form.useForm();

  const [calcResult, setCalcResult] = useState({});

  const [disabledCalcButton, setDisabledCalcButton] = useState(true);
  function onCreatedAtChange(e) {
    setCalcResult({});
    setDisabledCalcButton(!e);
  }

  function onNFieldChange(e) {
    setCalcResult({});
  }

  async function onClickCalcExample() {
    try {
      const values = await form.getFieldsValue();
      const result = await calcExample(values);
      setCalcResult(result);
    } catch (e) {
      notification.error({
        description: "Не удалось посчитать. Заполните все обязательные поля",
      });
    }
  }

  const initialValues = { ...data, date: null };

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onFinish={submitForm}>
      {mode !== "edit" && <StreamFormTypeSwitcher value={STREAM_TYPE_DYNAMIC} />}

      <Form.Item label="Название" name={FIELD_TITLE} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      {loading ? (
        <Skeleton />
      ) : (
        <>
          {error ? (
            <Text type="danger">Ошибка: не удалось загрузить dynamic_stream_rule</Text>
          ) : (
            <Row gutter={[30, 30]}>
              <Col xs={24} md={10}>
                <Row gutter={[15, 15]}>
                  <Col span={18}>
                    <Form.Item label="Пред-старт" name={FIELD_PRE_STARTED_AT_RULE} rules={[{ required: true }]}>
                      <Select options={options} onChange={onSelectChange(FIELD_PRE_STARTED_AT_RULE)} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    {shouldShowN[FIELD_PRE_STARTED_AT_RULE] && (
                      <Form.Item label="N" name={FIELD_PRE_STARTED_AT_N} rules={[{ required: true }]}>
                        <Input onChange={onNFieldChange} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={18}>
                    <Form.Item label="Старт" name={FIELD_STARTED_AT_RULE} rules={[{ required: true }]}>
                      <Select options={options} onChange={onSelectChange(FIELD_STARTED_AT_RULE)} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    {shouldShowN[FIELD_STARTED_AT_RULE] && (
                      <Form.Item label="N" name={FIELD_STARTED_AT_N} rules={[{ required: true }]}>
                        <Input onChange={onNFieldChange} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row gutter={[15, 15]}>
                  <Col span={18}>
                    <Form.Item label="Финиш" name={FIELD_ENDED_AT_RULE} rules={[{ required: true }]}>
                      <Select options={options} onChange={onSelectChange(FIELD_ENDED_AT_RULE)} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    {shouldShowN[FIELD_ENDED_AT_RULE] && (
                      <Form.Item label="N" name={FIELD_ENDED_AT_N} rules={[{ required: true }]}>
                        <Input onChange={onNFieldChange} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={{ span: 12, offset: 2 }}>
                <Space direction="vertical" size={30}>
                  <Space>
                    <Form.Item label="Пример" help="Выберите дату создания покупки" name="date">
                      <InputDate onChange={onCreatedAtChange} />
                    </Form.Item>
                    <Form.Item label=" ">
                      <Button type="default" onClick={onClickCalcExample} disabled={disabledCalcButton}>
                        Посчитать
                      </Button>
                    </Form.Item>
                  </Space>

                  <Space direction="vertical">
                    <Space>
                      <Text strong>Пред-старт:</Text>
                      <Text>{calcResult[FIELD_PRE_STARTED_AT] || "-"}</Text>
                    </Space>
                    <Space>
                      <Text strong>Старт:</Text>
                      <Text>{calcResult[FIELD_STARTED_AT] || "-"}</Text>
                    </Space>
                    <Space>
                      <Text strong>Окончание:</Text>
                      <Text>{calcResult[FIELD_ENDED_AT] || "-"}</Text>
                    </Space>
                  </Space>
                </Space>
              </Col>
            </Row>
          )}
        </>
      )}

      <Divider />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

function StreamCardData({ type, ...props }) {
  if (type === STREAM_TYPE_DYNAMIC) {
    return <DynamicStreamCardData {...props} />;
  }

  return <StaticStreamCardData {...props} />;
}

function StaticStreamCardData({ data }) {
  return (
    <CardDescriptions title="Карточка потока" layout="horizontal" bordered column={2} size="small">
      <Descriptions.Item label="Название">{_.get(data, FIELD_TITLE)}</Descriptions.Item>
      <Descriptions.Item label="Пред-старт">
        <DateCellRenderer value={_.get(data, FIELD_PRE_STARTED_AT)} format={DateFormats.withSeconds} />
      </Descriptions.Item>

      <Descriptions.Item label="Тренинг">
        <TrainingCellRenderer data={_.get(data, FIELD_TRAINING)} />
      </Descriptions.Item>
      <Descriptions.Item label="Старт">
        <DateCellRenderer value={_.get(data, FIELD_STARTED_AT)} format={DateFormats.withSeconds} />
      </Descriptions.Item>

      <Descriptions.Item label=""></Descriptions.Item>
      <Descriptions.Item label="Финиш">
        <DateCellRenderer value={_.get(data, FIELD_ENDED_AT)} format={DateFormats.withSeconds} />
      </Descriptions.Item>
    </CardDescriptions>
  );
}

function DynamicStreamCardData({ data }) {
  const { loading, data: rules, error } = useN8n2("admin.selector.dynamic_stream_rule.v1");

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <Text type="danger">Ошибка: не удалось загрузить dynamic_stream_rule</Text>;
  }

  return (
    <CardDescriptions title="Карточка потока" layout="horizontal" bordered column={2} size="small">
      <Descriptions.Item label="Название">{_.get(data, FIELD_TITLE)}</Descriptions.Item>

      <Descriptions.Item label="Пред-старт">
        <DynamicStreamRuleRenderer value={_.get(data, FIELD_PRE_STARTED_AT_RULE)} n={_.get(data, FIELD_PRE_STARTED_AT_N)} rules={rules} />
      </Descriptions.Item>

      <Descriptions.Item label="Тренинг">
        <TrainingCellRenderer data={_.get(data, FIELD_TRAINING)} />
      </Descriptions.Item>
      <Descriptions.Item label="Старт">
        <DynamicStreamRuleRenderer value={_.get(data, FIELD_STARTED_AT_RULE)} n={_.get(data, FIELD_STARTED_AT_N)} rules={rules} />
      </Descriptions.Item>

      <Descriptions.Item label=""></Descriptions.Item>
      <Descriptions.Item label="Финиш">
        <DynamicStreamRuleRenderer value={_.get(data, FIELD_ENDED_AT_RULE)} n={_.get(data, FIELD_ENDED_AT_N)} rules={rules} />
      </Descriptions.Item>
    </CardDescriptions>
  );
}

function StreamPage({ children, type = STREAM_TYPE_STATIC, hideEditButton }) {
  const { id, id_training } = useParams();

  const { loading, data, error } = useN8n2(`admin.${type}.details.v1`, { id }, [id, type]);
  const deleteStream = useN8n2Defer(`admin.${type}.delete.v1`);

  async function onDelete() {
    deleteStream({ id })
      .then(() => {
        notification.success({
          description: "Поток удалён",
        });
        history.push(`/trainings/${id_training}/streams`);
      })
      .catch(() => {
        notification.error({
          description: "Не удалось удалить поток",
        });
      });
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <CardHeader>
        {loading ? (
          <Skeleton.Input style={{ width: 300 }} active={true} />
        ) : (
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/trainings">Тренинги</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/trainings/${id_training}`}>
                <HTML2Unicode>{_.get(data, "training.title")}</HTML2Unicode>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/trainings/${id_training}/streams`}>Потоки</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
          </Breadcrumb>
        )}

        <CardButtons>
          {hideEditButton || (
            <Link to={getStreamEditRoutePath(id_training, id, type)} disabled={loading}>
              <Button type="primary" ghost={true} icon={<EditOutlined />} disabled={loading}>
                Редактировать поток
              </Button>
            </Link>
          )}
          <Popconfirm disabled={loading} title="Поток будет удалён. Продолжить?" onConfirm={onDelete} okText="Удалить" cancelText="Отмена">
            <Button type="danger" ghost={true} icon={<DeleteOutlined />} disabled={loading}>
              Удалить поток
            </Button>
          </Popconfirm>
        </CardButtons>
      </CardHeader>

      <Divider />

      {loading ? <Skeleton active /> : children({ data })}
    </>
  );
}

function StreamPurchasesInfo({ id, type }) {
  const { loading, data, error, refetch } = useN8n2(`admin.purchase.count_by_${type}.v1`, { id }, [id, type]);
  const recalcPurchases = useN8n2Defer(`admin.${type}.purchases_recalc.v1`);

  const onRecalc = () => {
    recalcPurchases({ id })
      .then(() => {
        notification.success({
          description: "Пересчёт покупок успешно запущен",
        });
        refetch();
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось запустить пересчёт покупок",
        });
      });
  };

  if (loading) {
    return <Skeleton.Input style={{ width: 500 }} active={true} />;
  }

  if (error) {
    return <Text type="danger">Ошибка: Не удалось загрузить данные о связанных покупках</Text>;
  }

  const totalCount = _.get(data, "total") || 0;
  const invalidCount = _.get(data, "invalid") || 0;
  const withManuallyDatesCount = _.get(data, "with_manually_dates") || 0;

  return (
    <Space direction="vertical" size={2}>
      <Space size={8}>
        <Text strong>Данные используются в {totalCount} покупках.</Text>
        <Button onClick={refetch} size="small" type="primary" icon={<RedoOutlined />}>
          Проверить актуальность
        </Button>
      </Space>
      {withManuallyDatesCount > 0 && <Text>Покупок, для который даты установлены вручную: {withManuallyDatesCount}.</Text>}
      {invalidCount > 0 && (
        <Space size={3}>
          <Text type="danger">Покупок, не соответствующих текущим настройкам: {invalidCount}.</Text>
          <Popconfirm title="Связанные покупки будут пересчитаны. Продолжить?" onConfirm={onRecalc} okText="Продолжить" cancelText="Отмена">
            <Text type="danger" underline style={{ cursor: "pointer" }}>
              Пересчитать покупки
            </Text>
          </Popconfirm>
        </Space>
      )}
    </Space>
  );
}

function StreamOffers({ id }) {
  const { loading, data, error } = useN8n2("admin.offer.list.v1", { id_stream: id }, [id]);

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title, record) => <Link to={`/offers/${record.id}`}>{title || <i>Нет названия</i>}</Link>,
    },
    {
      title: "Сценарий",
      dataIndex: "scenario",
      key: "scenario",
      render: (scenario, record) => <ScenarioCellRenderer trainingId={_.get(record, "training.id")} data={scenario} />,
    },
    {
      title: "Платформа",
      dataIndex: "platform",
      width: 140,
      render: (platform) => _.get(platform, "desc") || "-",
    },
    {
      title: "Цена",
      dataIndex: "price",
      width: 120,
      defaultValue: 0,
      align: "right",
      render: (price, record) => <OfferPriceCellRenderer price={price} marketing_price={record.marketing_price} />,
    },
  ];

  return <Table columns={columns} dataSource={data.items} />;
}

export function StreamCard({ type = STREAM_TYPE_STATIC }) {
  const { id, id_training, tab = "card" } = useParams();

  return (
    <StreamPage type={type}>
      {({ data }) => {
        return (
          <Space direction="vertical" size={20} style={{ width: "100%" }}>
            <StreamPurchasesInfo id={id} type={type} />

            <MyTabs
              activeKey={tab}
              resolvePath={(key) => getStreamCardRoutePath(id_training, id, type, key)}
              items={[
                { key: "card", title: "Основные поля", children: <StreamCardData data={data} type={type} /> },
                { key: "offers", title: "Предложения", children: <StreamOffers id={id} /> },
              ]}
            />
          </Space>
        );
      }}
    </StreamPage>
  );
}

export function StreamEdit({ type = STREAM_TYPE_STATIC }) {
  const { id, id_training } = useParams();

  const updateStream = useN8n2Defer(`admin.${type}.update.v1`);

  const onSubmitHandler = (submission) => {
    updateStream({ ...submission, id })
      .then(() => {
        showSuccessSaveMessage();
        history.push(getStreamCardRoutePath(id_training, id, type));
      })
      .catch(showFailureSaveMessage);
  };

  return (
    <StreamPage type={type} hideEditButton={true}>
      {({ data }) => {
        return <StreamBaseForm type={type} mode="edit" data={data} submitForm={onSubmitHandler} />;
      }}
    </StreamPage>
  );
}

export function StreamCreate({ type = STREAM_TYPE_STATIC }) {
  const { id_training } = useParams();

  const { loading, data } = useN8n2("admin.training.details.v1", { id: id_training }, [id_training]);
  const createStream = useN8n2Defer(`admin.${type}.create.v1`);

  const onSubmitHandler = (submission) => {
    createStream({ ...submission, id_training })
      .then(() => {
        showSuccessSaveMessage();
        history.push(`/trainings/${id_training}/streams`);
      })
      .catch(showFailureSaveMessage);
  };

  return (
    <>
      <CardHeader>
        {loading ? (
          <Skeleton.Input style={{ width: 300 }} active={true} />
        ) : (
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/trainings">Тренинги</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/trainings/${id_training}`}>
                <HTML2Unicode>{_.get(data, "title")}</HTML2Unicode>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/trainings/${id_training}/streams`}>Потоки</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Новый поток</Breadcrumb.Item>
          </Breadcrumb>
        )}
      </CardHeader>

      <Divider />

      <StreamBaseForm
        type={type}
        data={
          type === STREAM_TYPE_STATIC
            ? {
                pre_started_at: null,
                started_at: null,
                ended_at: null,
              }
            : {}
        }
        submitForm={onSubmitHandler}
      />
    </>
  );
}

export function StreamsTable() {
  const query = useQuery();

  const queryPage = parseInt(query.get("page"));
  const currentPage = !isNaN(queryPage) ? queryPage : 1;

  const queryPageSize = parseInt(query.get("pageSize"));
  const pageSize = !isNaN(queryPageSize) ? queryPageSize : 50;

  const type = query.get("type") || STREAM_TYPE_STATIC;

  const { loading, error, data } = useManyJsonRpc(
    useN8n2(
      `admin.${type}.list.v1`,
      {
        limit: pageSize,
        offset: pageSize * (currentPage - 1),
        require_offers_count: true,
      },
      [pageSize, currentPage, type]
    ),
    useN8n2("admin.selector.dynamic_stream_rule.v1")
  );

  function handleTableChange(pagination) {
    query.set("page", pagination.current);
    history.push(`/streams?${query.toString()}`);
  }

  function onShowSizeChange(current, size) {
    query.set("pageSize", size);
    history.push(`/streams?${query.toString()}`);
  }

  function onTypeFilterChange(e) {
    const type = e.target.value;
    if (type) {
      query.set("type", type);
    } else {
      query.delete("type");
    }

    query.delete("page");

    history.push(`/streams?${query.toString()}`);
  }

  if (error) {
    return <PageError />;
  }

  const [list, rules] = loading ? [{}, []] : data;

  const columns = [
    {
      title: "Тренинг",
      dataIndex: "training",
      key: "training",
      render: (training) => <TrainingCellRenderer data={training} />,
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      defaultValue: "Новый поток",
      render: (title, record) => <Link to={getStreamCardRoutePath(_.get(record, "training.id"), record.id, type)}>{title}</Link>,
    },
    {
      title: "Предложения",
      dataIndex: "offers_count",
      key: "offers_count",
      render: (offers_count, record) => {
        if (!offers_count) {
          return null;
        }
        return <Link to={getStreamCardRoutePath(_.get(record, "training.id"), record.id, type, "offers")}>{offers_count}</Link>;
      },
    },
    {
      title: "Пред-начало",
      render: getStreamRuleRenderer(type, rules, FIELD_PRE_STARTED_AT, FIELD_PRE_STARTED_AT_RULE, FIELD_PRE_STARTED_AT_N),
    },
    {
      title: "Старт",
      render: getStreamRuleRenderer(type, rules, FIELD_STARTED_AT, FIELD_STARTED_AT_RULE, FIELD_STARTED_AT_N),
    },
    {
      title: "Финиш",
      render: getStreamRuleRenderer(type, rules, FIELD_ENDED_AT, FIELD_ENDED_AT_RULE, FIELD_ENDED_AT_N),
    },
  ];

  return (
    <>
      <StreamTypeSwitcher value={type} onChange={onTypeFilterChange} />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={list.items}
        rowClassName={(record) => (type === STREAM_TYPE_DYNAMIC || new Date(record.ended_at) - new Date() > 0 ? null : "tr_muted")}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: list.total,
          onShowSizeChange,
        }}
        onChange={handleTableChange}
      />
    </>
  );
}

export function TrainingStreams({ id }) {
  const { loading, error, data = {} } = useManyJsonRpc(
    useN8n2("admin.dynamic_stream.list.v1", { id_training: id, require_offers_count: true }, [id]),
    useN8n2("admin.stream.list.v1", { id_training: id, require_offers_count: true }, [id]),
    useN8n2("admin.selector.dynamic_stream_rule.v1")
  );

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  const [dynamicStreamData, staticStreamData, rules] = data;
  const items = dynamicStreamData.items
    .map((item) => ({ ...item, type: STREAM_TYPE_DYNAMIC }))
    .concat(staticStreamData.items.map((item) => ({ ...item, type: STREAM_TYPE_STATIC })));

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      defaultValue: "Новый поток",
      render: (title, record) => <Link to={getStreamCardRoutePath(id, record.id, record.type)}>{title}</Link>,
    },
    {
      title: "Предложения",
      dataIndex: "offers_count",
      key: "offers_count",
      render: (offers_count, record) => {
        if (!offers_count) {
          return null;
        }
        return <Link to={getStreamCardRoutePath(id, record.id, record.type, "offers")}>{offers_count}</Link>;
      },
    },
    {
      title: "Пред-начало",
      render: (v, r) => getStreamRuleRenderer(r.type, rules, FIELD_PRE_STARTED_AT, FIELD_PRE_STARTED_AT_RULE, FIELD_PRE_STARTED_AT_N)(v, r),
    },
    {
      title: "Старт",
      render: (v, r) => getStreamRuleRenderer(r.type, rules, FIELD_STARTED_AT, FIELD_STARTED_AT_RULE, FIELD_STARTED_AT_N)(v, r),
    },
    {
      title: "Финиш",
      render: (v, r) => getStreamRuleRenderer(r.type, rules, FIELD_ENDED_AT, FIELD_ENDED_AT_RULE, FIELD_ENDED_AT_N)(v, r),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={items}
        rowClassName={(record) => (record.type === STREAM_TYPE_DYNAMIC || new Date(record.ended_at) - new Date() > 0 ? null : "tr_muted")}
      />

      <Button type="dashed" onClick={() => history.push(getStreamCreateRoutePath(id))} style={{ position: "relative", top: "-45px" }}>
        Добавить поток
      </Button>
    </>
  );
}
