import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Select, Skeleton, DatePicker, Radio, Typography, notification } from "antd";

import { fetchWithJsonRpc, fetchWith401 } from "../helpers/helpers";
import { apiPath, DateFormats } from "../constants";
import { useN8n2 } from "../hooks/useN8n2";
import { history } from "../history";

const { Option } = Select;
const { Text } = Typography;

function getTitle(row, title) {
  if (typeof title === "function") {
    return title(row);
  }

  return _.get(row, title);
}

export function AsyncSelect2({
  jrpcMethod,
  jrpcParams = {},
  n8nVersion,
  title = "title",
  idKey = "id",
  onBlur,
  autoFocus,
  mode,
  value,
  deps,
  ...selectProps
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const refreshDeps = Array.isArray(deps) ? [jrpcMethod, ...deps] : [jrpcMethod];
  useEffect(() => {
    fetchWithJsonRpc(jrpcMethod, jrpcParams, {}, n8nVersion)
      .then((result) => {
        let items = [];
        if (Array.isArray(result.items)) {
          items = result.items;
        } else if (Array.isArray(result)) {
          items = result;
        }
        setData(items);
        setIsLoaded(true);
      })
      .catch((e) => {
        typeof onBlur === "function" && onBlur();
        notification.error({
          message: "Ошибка",
          description: "Не удалось загрузить список элементов для выбора",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refreshDeps);

  if (!isLoaded) {
    return <Skeleton.Input style={{ width: _.get(selectProps, "style.width") || 200 }} active={true} />;
  }

  return (
    <Select
      autoFocus={autoFocus}
      defaultOpen={autoFocus}
      onBlur={onBlur}
      mode={mode}
      value={!value && mode === "multiple" ? [] : value}
      {...selectProps}
    >
      {data.map((r) => (
        <Option key={r[idKey]} value={r[idKey]}>
          {getTitle(r, title)}
        </Option>
      ))}
    </Select>
  );
}

export function AsyncRadioGroup({ jrpcMethod, jrpcParams = {}, title = "title", idKey = "id", onBlur, value, deps, ...props }) {
  const refreshDeps = Array.isArray(deps) ? [jrpcMethod, ...deps] : [jrpcMethod];
  const { loading, data = {}, error } = useN8n2(jrpcMethod, jrpcParams, refreshDeps);

  useEffect(() => {
    if (!error) {
      return;
    }

    typeof onBlur === "function" && onBlur();
  }, [error, onBlur]);

  if (loading) {
    return <Skeleton.Input style={{ width: _.get(props, "style.width") || 200 }} active={true} />;
  }

  if (error) {
    return <Text type="danger">Ошибка: Не удалось загрузить список элементов для выбора</Text>;
  }

  let items = [];
  if (Array.isArray(data.items)) {
    items = data.items;
  } else if (Array.isArray(data)) {
    items = data;
  }

  return (
    <Radio.Group
      value={value}
      optionType="button"
      options={items.map((i) => ({ label: getTitle(i, title), value: i[idKey] }))}
      {...props}
    />
  );
}

export const defaultSearchFilter = (input, option) => {
  const id = _.get(option, "props.value") || _.get(option, "value") || "";
  const title = _.get(option, "props.children") || _.get(option, "label") || "";

  return input.toLowerCase().trim() === id || title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const videoSearchFilter = (input, option) => {
  const title = _.get(option, "props.children") || "";
  const id = _.get(option, "props.value") || "";

  return id.indexOf(input.trim()) >= 0 || title.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
};

export function AsyncSelect({ resource, title, idKey = "id", onBlur, autoFocus = true, mode, value, ...selectProps }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchWith401(`${apiPath}/${resource}`, {}, null, history)
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch(() => {
        typeof onBlur === "function" && onBlur();
        notification.error({
          message: "Ошибка",
          description: "Не удалось загрузить список элементов для выбора",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  if (!isLoaded) {
    return <p>Loading...</p>;
  }

  return (
    <Select
      autoFocus={autoFocus}
      defaultOpen={autoFocus}
      onBlur={onBlur}
      mode={mode}
      value={!value && mode === "multiple" ? [] : value}
      {...selectProps}
    >
      {data.map((r) => (
        <Option key={r[idKey]} value={r[idKey]}>
          {_.get(r, title)}
        </Option>
      ))}
    </Select>
  );
}

export function InputDate(props) {
  const { onSave, value, onChange, ...otherProps } = props;

  const date = moment.utc(value);
  const initValue = date.isValid() ? date.local() : null;

  const onChangeHandler = (value) => {
    let newValue = value;
    if (moment.isMoment(newValue)) {
      newValue = newValue.toISOString();
    }
    onChange(newValue);
  };

  return (
    <DatePicker
      showTime
      format={DateFormats.withSeconds}
      value={initValue}
      onBlur={onSave}
      onOk={onSave}
      onChange={onChangeHandler}
      {...otherProps}
    />
  );
}
