import React, { useEffect, useState, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import "moment/locale/ru";
import _ from "lodash";
import styled from "styled-components";
import {
  Alert,
  Button,
  Table,
  Divider,
  Breadcrumb,
  Checkbox,
  Radio,
  Popconfirm,
  Skeleton,
  Switch as AntdSwitch,
  Space,
  Typography,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from "antd";
import {
  LinkOutlined,
  RedoOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { blue, green } from "@ant-design/colors";

import { TrainingStreams } from "./Stream";

import EditableTable2 from "../components/EditableTable2";
import SortableTable from "../components/SortableTable";
import { InputImage } from "../components/InputImage";
import { Image } from "../components/Image";
import { InputDate } from "../components/FormInput";
import {
  ScenarioCellRenderer,
  StreamCellRenderer,
  OfferPriceCellRenderer,
} from "../components/CellRenderer";
import {
  AsyncSelect,
  AsyncSelect2,
  defaultSearchFilter,
} from "../components/FormInput";
import {
  ReferenceField,
  ReferenceArrayField,
} from "../components/ReferenceField";
import { PageError } from "../components/PageError";
import { HTML2Unicode } from "../components/HTML2Unicode";
import { MyTabs } from "../components/Tabs";

import { apiPath, DateFormats } from "../constants";
import {
  callAdminRpc,
  getSiteUrl,
  fetchWith401,
  fetchWithJsonRpc,
  incrementPosition,
  callN8n2,
} from "../helpers/helpers";
import {
  showFailureSaveMessage,
  showSuccessSaveMessage,
} from "../helpers/notification";
import {
  handleSave,
  handleSaveN8n,
  handleDelete,
  handleDeleteN8n,
} from "../helpers/request";
import { useQuery } from "../hooks/useQuery";
import { useN8n2 } from "../hooks/useN8n2";
import { history } from "../history";
import { CardScreen } from "../jhtml";

import "../fonts/CeraPro/stylesheet.css";

const { Text } = Typography;
const { Option } = Select;

const TRAINING_VISIBILITY_VISIBLE = "visible";
const TRAINING_VISIBILITY_HIDDEN = "hidden";
const TRAINING_VISIBILITY_SECRET = "secret";

function getNextVisibility(visibility) {
  switch (visibility) {
    case TRAINING_VISIBILITY_HIDDEN:
      return TRAINING_VISIBILITY_VISIBLE;
    case TRAINING_VISIBILITY_VISIBLE:
      return TRAINING_VISIBILITY_SECRET;
    default:
      return TRAINING_VISIBILITY_HIDDEN;
  }
}

function TrainingBaseForm({ data, submitForm }) {
  return (
    <Form initialValues={data} layout="vertical" onFinish={submitForm}>
      <Form.Item label="Позиция" name="position">
        <InputNumber />
      </Form.Item>

      <Form.Item label="Заголовок" name="title">
        <Input />
      </Form.Item>

      <Form.Item label="Картинка" name="image">
        <InputImage />
      </Form.Item>

      <Form.Item label="Описание" name="description">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Адрес лендинга" name="landing_url">
        <Input />
      </Form.Item>

      {_.get(data, "id") && (
        <Form.Item
          label="Сценарий по умолчанию"
          name="id_scenario_default"
          rules={[{ required: true }]}
        >
          <AsyncSelect2
            jrpcMethod="admin.scenario.list.v1"
            jrpcParams={{ id_training: data.id }}
            title="title"
            style={{ width: "500px" }}
            showSearch={true}
            filterOption={defaultSearchFilter}
            deps={[data.id]}
          />
        </Form.Item>
      )}

      <Form.Item label="Тренер" name="id_teacher">
        <AsyncSelect2
          jrpcMethod="admin.teacher.list.v1"
          title="name"
          showSearch={true}
          filterOption={defaultSearchFilter}
        />
      </Form.Item>

      <Form.Item label="Опубликованно в" name="published_at">
        <InputDate showTime={false} format={DateFormats.dateOnly} />
      </Form.Item>

      <Form.Item label="Видимость" name="visibility">
        <Select
          style={{ width: 300 }}
          defaultValue={TRAINING_VISIBILITY_HIDDEN}
        >
          <Option value={TRAINING_VISIBILITY_HIDDEN}>Скрытый</Option>
          <Option value={TRAINING_VISIBILITY_VISIBLE}>Доступный</Option>
          <Option value={TRAINING_VISIBILITY_SECRET}>Cекретный</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Взаимосвязанный контент"
        name="dependent_content"
        rules={[{ required: true }]}
      >
        <Select
          style={{ width: 300 }}
          options={[
            { label: "Да (контент связан логически)", value: true },
            { label: "Нет (контент логически не связан)", value: false },
          ]}
          placeholder="Тип контента не установлен"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 10 }}>
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
}

export function TrainingsTable() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const query = useQuery();

  const visibilityItems = {
    all: {
      label: "Все",
    },
    [TRAINING_VISIBILITY_HIDDEN]: {
      label: "Скрытые",
    },
    [TRAINING_VISIBILITY_VISIBLE]: {
      label: "Видимые",
    },
    [TRAINING_VISIBILITY_SECRET]: {
      label: "Секретные",
    },
  };

  const defaultVisibility = Object.keys(visibilityItems)[0];
  const visibility = query.get("visibility") || defaultVisibility;

  function onVisibilityFilterChange(value) {
    history.push(`/trainings?visibility=${value}`);
  }

  function VisibilityFilter({ items, defaultValue, onChange }) {
    return (
      <div>
        <span style={{ marginRight: 5 }}>Показать: </span>
        <Select
          defaultValue={defaultValue}
          style={{ width: 120 }}
          onChange={onChange}
        >
          {items.map((item) => (
            <Option key={item.value} value={item.item}>
              {item.label}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  const loadData = useCallback(() => {
    fetchWithJsonRpc("admin.training.list.v3", { visibility }).then((r) => {
      setData(_.get(r, "items") || []);
      setIsLoaded(true);
    });
  }, [visibility]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function createNew() {
    history.push("/trainings/create");
  }

  function SwitchVisibilityButton({ defaultValue, recordId }) {
    const [visibility, setVisibility] = useState(defaultValue);

    function switchVisibility() {
      const nextVisibility = getNextVisibility(visibility);

      fetchWithJsonRpc("admin.training.update_visibility.v1", {
        id: recordId,
        visibility: nextVisibility,
      })
        .then(() => {
          setVisibility(nextVisibility);
        })
        .catch(() => {
          notification.error({
            description: "Не удалось изменить видимость",
          });
        });
    }

    return (
      <Button type="default" size="small" onClick={() => switchVisibility()}>
        {visibility === TRAINING_VISIBILITY_HIDDEN && "Скрытый"}
        {visibility === TRAINING_VISIBILITY_VISIBLE && "Доступный"}
        {visibility === TRAINING_VISIBILITY_SECRET && "Cекретный"}
      </Button>
    );
  }

  const columns = [
    {
      title: "Картинка",
      dataIndex: "image",
      key: "image",
      render: (image, record) => (
        <Link to={`/trainings/${record.id}`}>
          <Image width={100} src={image} preview={false} />
        </Link>
      ),
      width: 100,
    },

    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Link to={`/trainings/${record.id}`}>{text}</Link>
      ),
    },

    {
      title: "Тренер",
      dataIndex: "teacher",
      key: "teacher",
      render: (teacher) => _.get(teacher, "name") || "-",
    },

    {
      title: "Видимость",
      dataIndex: "visibility",
      key: "visibility",
      fixed: "right",
      width: "140px",
      align: "center",
      render: (visibility, record) => {
        return (
          <SwitchVisibilityButton
            defaultValue={visibility}
            recordId={record.id}
          />
        );
      },
    },
  ];

  const sortHandler = (from, to) => {
    setIsLoaded(false);
    try {
      fetchWithJsonRpc("admin.training.sort.v2", {
        from: {
          ..._.pick(data[from], ["id", "position"]),
          index: from,
        },
        to: {
          ..._.pick(data[to], ["id", "position"]),
          index: to,
        },
      }).then(() => {
        loadData();
      });
    } catch (e) {
      notification.error({
        description: "Не удалось изменить позицию тренинга. Попробуйте ещё раз",
      });
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="primary" onClick={createNew}>
          + Добавить
        </Button>
        <VisibilityFilter
          items={Object.keys(visibilityItems).map((key) => ({
            label: _.get(visibilityItems[key], "label", ""),
            value: key,
          }))}
          defaultValue={visibility}
          onChange={onVisibilityFilterChange}
        />
      </div>
      <Divider />
      {!isLoaded ? (
        <Table loading={true} />
      ) : (
        <SortableTable
          columns={columns}
          dataSource={data}
          rowKey="id"
          onSort={sortHandler}
          pagination={{
            pageSize: 50,
          }}
        />
      )}
    </div>
  );
}

export function TrainingCreate() {
  function submitForm(submission) {
    callN8n2("admin.training.create.v1", submission)
      .then(({ id }) => {
        showSuccessSaveMessage();
        history.push(`/trainings/${id}`);
      })
      .catch(showFailureSaveMessage);
  }

  return <TrainingBaseForm data={{}} submitForm={submitForm} />;
}

const TrainingEditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TrainingScenarioSelect = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: 10px;
`;

function TrainingLessonsTable({ id, data, scenarioId }) {
  const tgiTableColumns = [
    {
      title: "#",
      dataIndex: "position",
      width: 30,
      editable: true,
      defaultValue: incrementPosition(10),
      render: (t, r) => t || 0,
    },
    {
      title: "Урок",
      dataIndex: "id_lesson",
      editable: true,
      defaultValue: null,
      render: (t, r) => (
        <ReferenceField
          resource="lesson"
          source={r.id_lesson}
          foo="id"
          bar="title"
          link={`/lessons/${r.id_lesson}`}
          placeholder="Без урока"
        />
      ),
      inputNode: (ref, save, r) => {
        return (
          <AsyncSelect2
            jrpcMethod="admin.lesson.list.v1"
            jrpcParams={{ sort: { by: "title", order: "asc" } }}
            autoFocus={true}
            defaultValue={r.id_lesson}
            title="title"
            resource="lesson"
            onBlur={save}
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        );
      },
    },
    {
      title: "Задание",
      dataIndex: "id_practic",
      editable: true,
      defaultValue: null,
      render: (t, r) => (
        <ReferenceField
          resource="practic"
          source={r.id_practic}
          foo="id"
          bar="title"
          link={`/practics/${r.id_practic}`}
          placeholder="Без задания"
        />
      ),
      inputNode: (ref, save, r) => {
        return (
          <AsyncSelect
            defaultValue={r.id_practic}
            title="title"
            resource="practic"
            onBlur={save}
            showSearch={true}
            filterOption={defaultSearchFilter}
          />
        );
      },
    },
  ];

  if (scenarioId) {
    tgiTableColumns.push(
      {
        dataIndex: "practic_auto_accept",
        editable: true,
        alwaysEditable: true,
        inputNode: (ref, save, r) => {
          if (!r.has_scenario_rule) {
            return null;
          }
          const options = [
            {
              label: "Куратор",
              value: false,
            },
            {
              label: "Авто",
              value: true,
            },
          ];
          return (
            <Radio.Group
              options={options}
              onChange={save}
              defaultValue={r.practic_auto_accept}
              optionType="button"
              buttonStyle="solid"
              size="small"
              style={{ whiteSpace: "nowrap" }}
            />
          );
        },
      },
      {
        title: "В сценарии",
        dataIndex: "has_scenario_rule",
        editable: true,
        alwaysEditable: true,
        valuePropName: "checked",
        inputNode: (ref, save, r) => {
          return (
            <AntdSwitch
              defaultChecked={r.has_scenario_rule}
              checkedChildren="В сценарии"
              unCheckedChildren="Не используется"
              onChange={save}
              style={{ whiteSpace: "nowrap" }}
            />
          );
        },
      },
      {
        title: "Правило доступа",
        dataIndex: "access_rule",
        editable: (r) => r.has_scenario_rule,
        defaultValue: null,
        render: (t, r) => {
          if (!r.has_scenario_rule) {
            return null;
          }
          return (
            <ReferenceField
              resource="scenario_rule_access_rule_dict"
              source={t}
              foo="id"
              bar="desc"
            />
          );
        },
        inputNode: (ref, save, r) => {
          return (
            <AsyncSelect
              onBlur={save}
              defaultValue={r.access_rule}
              title="desc"
              resource="scenario_rule_access_rule_dict"
            />
          );
        },
      },
      {
        title: "Период доступа",
        dataIndex: "access_period",
        editable: (r) => r.has_scenario_rule,
        defaultValue: [],
        render: (t, r) => {
          if (!r.has_scenario_rule) {
            return null;
          }
          return (
            <ReferenceArrayField
              resource="scenario_rule_access_period_dict"
              source={t}
              foo="id"
              bar="desc"
              color={blue.primary}
            />
          );
        },
        inputNode: (ref, save, r) => {
          return (
            <AsyncSelect
              onBlur={save}
              mode="multiple"
              defaultValue={r.access_period}
              title="desc"
              resource="scenario_rule_access_period_dict"
            />
          );
        },
      }
    );
  }

  const tgiTableHandleSave = (r) => {
    return (data, onSuccess) => {
      const params = {
        ...data,
        id_training_group: r.id,
        id_scenario: scenarioId,
      };

      callAdminRpc("training_group_item_update_v1", { params })
        .then((result) => {
          onSuccess({
            ...data,
            id: result.id,
            access_period: data.has_scenario_rule ? data.access_period : [],
            access_rule: data.has_scenario_rule ? data.access_rule : null,
          });
        })
        .catch(showFailureSaveMessage);
    };
  };

  const deleteTgiHandler = (e, succ) => {
    callAdminRpc("training_group_item_delete_v1", { id: e.id }).catch(
      showFailureSaveMessage
    );
  };

  const deleteTgHandler = (e, succ) => {
    callAdminRpc("training_group_delete_v1", { id: e.id }).catch(
      showFailureSaveMessage
    );
  };

  return (
    <EditableTable2
      buttonAddLabel="Добавить группу"
      handleDelete={deleteTgHandler}
      handleSave={handleSave(
        history,
        "training_group",
        id,
        ["training_group_item", "key"],
        { id_training: id }
      )}
      columns={[
        {
          title: "#",
          dataIndex: "position",
          width: 30,
          editable: true,
          defaultValue: 0,
          render: (t, r) => t || 0,
        },

        {
          title: "Название группы",
          dataIndex: "title",
          width: "30%",
          editable: true,
          defaultValue: null,
          render: (t, r) => {
            if (!r.title_hidden && t) {
              return (
                <Space align="normal">
                  <CheckCircleOutlined
                    style={{ color: green.primary, fontSize: 22 }}
                  />
                  {t}
                </Space>
              );
            }

            return t || <i>Нет названия</i>;
          },
        },

        {
          title: "Скрыть заголовок?",
          dataIndex: "title_hidden",
          render: (text) => (text ? "Да" : "Нет"),
          editable: true,
          valuePropName: "checked",
          defaultValue: false,
          inputNode: (ref, save) => {
            return <Checkbox onBlur={save} />;
          },
        },

        {
          title: "Вариант отображения",
          dataIndex: "display",
          editable: true,
          defaultValue: "with_button",
          render: (t, r) => (
            <ReferenceField
              resource="training_group_display_dict"
              source={r.display}
              foo="id"
              bar="desc"
            />
          ),
          valuePropName: "defaultValue",
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect
                defaultValue={r.display}
                title="desc"
                resource="training_group_display_dict"
                onBlur={save}
              />
            );
          },
        },
      ]}
      dataSource={data}
      rowKey="id"
      expandable={{
        showExpandAllButtons: true,
        expandedRowRender: (r) => (
          <EditableTable2
            showHeader={false}
            handleSave={tgiTableHandleSave(r)}
            handleDelete={deleteTgiHandler}
            style={{ marginLeft: 20 }}
            buttonAddLabel="Добавить урок"
            addExtraButtons={() => (
              <Button
                key="create_new_lesson"
                type="dashed"
                href={`/lessons/create/${r.id}`}
                target="_blank"
              >
                Создать новый урок
              </Button>
            )}
            columns={tgiTableColumns}
            dataSource={r.training_group_item}
          />
        ),
      }}
    />
  );
}

function TrainingLessonsSettings({ trainingId, trainingData, scenarioId }) {
  const context = useContext(ScenarioPurchasesContext);
  // const { isLoaded, error, data, reloadData, onRecalc } = context;
  const { onRecalc2 } = context;

  return (
    <>
      <Popconfirm
        title="Связанные покупки будут пересчитаны. Продолжить?"
        onConfirm={onRecalc2}
        okText="Продолжить"
        cancelText="Отмена"
      >
        <Text type="danger" underline style={{ cursor: "pointer" }}>
          Пересчитать покупки
        </Text>
      </Popconfirm>
      {/* <ScenarioPurchasesInfo data={data} isLoaded={isLoaded} error={error} onRecalc={onRecalc} onReload={reloadData} /> */}
      <TrainingLessonsTable
        id={trainingId}
        data={trainingData}
        scenarioId={scenarioId}
      />
    </>
  );
}

function TrainingLessons({ id }) {
  const query = useQuery();
  const scenarioId = query.get("scenario") || null;

  const [data, setData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const loadData = useCallback(() => {
    setIsLoaded(false);

    fetchWithJsonRpc("admin.training_group.list.v1", {
      id_training: id,
      id_scenario: scenarioId,
    })
      .then((result) => {
        setData(result);
        setIsLoaded(true);
      })
      .catch((e) => {
        setError(e);
      });
  }, [scenarioId, id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleScenarioChange = (value) => {
    if (value) {
      query.set("scenario", value);
    } else {
      query.delete("scenario");
    }
    history.push(`/trainings/${id}/lessons?${query.toString()}`);
  };

  if (error) {
    return <PageError />;
  }

  if (!isLoaded) {
    return <Table loading={true} />;
  }

  return (
    <Space direction="vertical" size={10} style={{ width: "100%" }}>
      <TrainingScenarioSelect>
        Сценарий:
        <AsyncSelect2
          jrpcMethod="admin.scenario.list.v1"
          jrpcParams={{ id_training: id }}
          deps={[id]}
          title="title"
          showSearch={true}
          filterOption={defaultSearchFilter}
          defaultValue={scenarioId}
          placeholder="Сценарий не выбран"
          allowClear={true}
          style={{ width: 300 }}
          onChange={handleScenarioChange}
        />
      </TrainingScenarioSelect>

      {scenarioId ? (
        <ScenarioPurchasesContextProvider id={scenarioId}>
          <TrainingLessonsSettings
            scenarioId={scenarioId}
            trainingId={id}
            trainingData={data}
          />
        </ScenarioPurchasesContextProvider>
      ) : (
        <TrainingLessonsTable id={id} data={data} scenarioId={scenarioId} />
      )}
    </Space>
  );
}

const ScenarioPurchasesContext = React.createContext();

const ScenarioPurchasesContextProvider = ({ id, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const loadData = useCallback(
    (should_calc_invalid) => {
      setError(null);
      setIsLoaded(false);

      fetchWithJsonRpc("admin.scenario.purchases_count.v2", {
        id,
        should_calc_invalid,
      })
        .then(setData)
        .catch((e) => {
          setError(e);
          notification.error({
            message: "Ошибка",
            description: "Не удалось загрузить данные о связанных покупках",
          });
        })
        .finally(() => setIsLoaded(true));
    },
    [id]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onRecalc = () => {
    fetchWithJsonRpc("admin.scenario.purchases_recalc.v1", { id })
      .then(() => {
        notification.success({
          description: "Пересчёт покупок успешно запущен",
        });
        loadData();
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось запустить пересчёт покупок",
        });
      });
  };

  const onRecalc2 = () => {
    callN8n2("admin.purchase.force_recalc.v1", { id_scenario: id })
      .then(() => {
        notification.success({
          description: "Пересчёт покупок успешно запущен",
        });
      })
      .catch((e) => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось запустить пересчёт покупок",
        });
      });
  };

  const reloadData = () => loadData(true);

  return (
    <ScenarioPurchasesContext.Provider
      value={{
        isLoaded,
        error,
        data,
        reloadData,
        onRecalc,
        onRecalc2,
      }}
    >
      {children}
    </ScenarioPurchasesContext.Provider>
  );
};

function TrainingScenarioRules({ row, trainingId }) {
  const deleteHandler = (e, succ) => {
    handleDeleteN8n(
      "scenario_rule",
      { id_scenario: row.id },
      "v2"
    )(e, succ).catch((e) => console.error(e));
  };

  const saveHandler = (s, succ) => {
    handleSaveN8n(
      "scenario_rule",
      { id_scenario: row.id },
      null,
      "v2"
    )(s, succ).catch(showFailureSaveMessage);
  };

  return (
    <EditableTable2
      handleDelete={deleteHandler}
      handleSave={saveHandler}
      buttonAddLabel="Добавить правило"
      style={{ marginLeft: 20 }}
      columns={[
        {
          title: "Урок",
          dataIndex: "id_training_group_item",
          editable: true,
          render: (t, r) => {
            return (
              <ReferenceField
                resource="training_group_item"
                source={t || null}
                foo="id"
                bar="lesson.title"
                select="id,lesson(id,title)"
                link={(data) => `/lessons/${_.get(data, "lesson.id")}`}
              />
            );
          },
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect
                onBlur={save}
                defaultValue={r.id_training_group_item}
                idKey="id_tgi"
                title="title"
                resource={`admin_lesson?id_training=eq.${trainingId}&select=id_tgi,title`}
              />
            );
          },
        },
        {
          title: "Период доступа",
          dataIndex: "access_period",
          width: "30%",
          editable: true,
          defaultValue: [],
          render: (t, r) => (
            <ReferenceArrayField
              resource="scenario_rule_access_period_dict"
              source={t}
              foo="id"
              bar="desc"
            />
          ),
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect
                onBlur={save}
                mode="multiple"
                defaultValue={r.access_period}
                title="desc"
                resource="scenario_rule_access_period_dict"
              />
            );
          },
        },

        {
          title: "Правило доступа",
          dataIndex: "access_rule",
          width: "30%",
          editable: true,
          defaultValue: null,
          render: (t, r) => (
            <ReferenceField
              resource="scenario_rule_access_rule_dict"
              source={t}
              foo="id"
              bar="desc"
            />
          ),
          inputNode: (ref, save, r) => {
            return (
              <AsyncSelect
                onBlur={save}
                defaultValue={r.access_rule}
                title="desc"
                resource="scenario_rule_access_rule_dict"
              />
            );
          },
        },
      ]}
      dataSource={(row.scenario_rule || []).sort(function (a, b) {
        const tgAPos = _.get(a, "training_group_item.training_group.position");
        const tgBPos = _.get(b, "training_group_item.training_group.position");
        const tgiAPos = _.get(a, "training_group_item.position");
        const tgiBPos = _.get(b, "training_group_item.position");
        const tgPosCompare = tgAPos - tgBPos;
        if (tgPosCompare !== 0) {
          return tgPosCompare;
        }
        return tgiAPos - tgiBPos;
      })}
    />
  );
}

function ScenarioPurchasesInfo({
  isLoaded,
  error,
  data,
  onRecalc,
  onReload,
  style,
}) {
  if (!isLoaded) {
    return <Skeleton.Input style={{ width: 500, ...style }} active={true} />;
  }

  if (error) {
    return null;
  }

  const totalCount = _.get(data, "total") || 0;
  const invalidCount = _.get(data, "invalid") || 0;

  return (
    <Space direction="vertical" size={4} style={style}>
      <Space size={8}>
        <Text strong>Данные используются в {totalCount} покупках.</Text>
        <Button
          onClick={onReload}
          size="small"
          type="primary"
          icon={<RedoOutlined />}
        >
          Проверить актуальность
        </Button>
      </Space>
      {invalidCount > 0 && (
        <Space size={3}>
          <Text type="danger">
            Покупок, не соответствующих текущим настройкам: {invalidCount}.
          </Text>
          <Popconfirm
            title="Связанные покупки будут пересчитаны. Продолжить?"
            onConfirm={onRecalc}
            okText="Продолжить"
            cancelText="Отмена"
          >
            <Text type="danger" underline style={{ cursor: "pointer" }}>
              Пересчитать покупки
            </Text>
          </Popconfirm>
        </Space>
      )}
    </Space>
  );
}

function TrainingScenarioSettings({ row, trainingId }) {
  const context = useContext(ScenarioPurchasesContext);
  const { isLoaded, error, data, reloadData, onRecalc } = context;

  return (
    <Space direction="vertical" size={10} style={{ width: "100%" }}>
      <ScenarioPurchasesInfo
        data={data}
        isLoaded={isLoaded}
        error={error}
        onRecalc={onRecalc}
        onReload={reloadData}
        style={{ marginLeft: 20 }}
      />
      <TrainingScenarioRules row={row} trainingId={trainingId} />
    </Space>
  );
}

function TrainingScenarios({ id }) {
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    // fixme : use fetchWithJsonRpc
    // admin.scenario.list_by_training.v1
    fetchWith401(
      `${apiPath}/training?id=eq.${id}&select=scenario!training_fk(*,scenario_rule(*,training_group_item(*,training_group(position),lesson(title))))`,
      {
        headers: {
          Accept: "application/vnd.pgrst.object+json",
        },
      },
      (result) => {
        setData(result);
      },
      history
    )
      .catch(setError)
      .finally(() => {
        setIsLoaded(true);
      });
  }, [id]);

  if (error) {
    return <PageError />;
  }

  if (!isLoaded) {
    return <Table loading={true} />;
  }

  return (
    <EditableTable2
      handleDelete={handleDelete(history, "scenario")}
      handleSave={handleSave(
        history,
        "scenario",
        id,
        ["training_group_item", "scenario_rule", "key"],
        { id_training: id }
      )}
      buttonAddLabel="Добавить сценарий"
      columns={[
        {
          title: "Название",
          dataIndex: "title",
          key: "title",
          editable: true,
          defaultValue: null,
          render: (t, r) => t || "-",
        },
        {
          title: "Описание",
          dataIndex: "description",
          key: "description",
          editable: true,
          defaultValue: null,
          render: (t, r) => t || "-",
        },
      ]}
      dataSource={data.scenario}
      expandable={{
        expandedRowRender: (r) => (
          <ScenarioPurchasesContextProvider id={r.id}>
            <TrainingScenarioSettings row={r} trainingId={id} />
          </ScenarioPurchasesContextProvider>
        ),
      }}
    />
  );
}

function TrainingOffers({ id }) {
  const {
    loading,
    data = {},
    error,
  } = useN8n2("admin.offer.list.v1", { id_training: id }, [id]);

  if (error) {
    return <PageError />;
  }

  if (loading) {
    return <Table loading={true} />;
  }

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title, record) => (
        <Link to={`/offers/${record.id}`}>{title || <i>Нет названия</i>}</Link>
      ),
    },
    {
      title: "Поток",
      dataIndex: "stream",
      key: "stream",
      render: (stream) => (
        <StreamCellRenderer title={stream.title} link={stream.link} />
      ),
    },
    {
      title: "Сценарий",
      dataIndex: "scenario",
      key: "scenario",
      render: (scenario, record) => (
        <ScenarioCellRenderer
          trainingId={_.get(record, "training.id")}
          data={scenario}
        />
      ),
    },
    {
      title: "Платформа",
      dataIndex: "platform",
      width: 140,
      render: (platform) => _.get(platform, "desc") || "-",
    },
    {
      title: "Цена",
      dataIndex: "price",
      width: 120,
      defaultValue: 0,
      align: "right",
      render: (price, record) => (
        <OfferPriceCellRenderer
          price={price}
          marketing_price={record.marketing_price}
        />
      ),
    },
  ];

  return <Table columns={columns} dataSource={data.items} />;
}

export function TrainingEdit() {
  const { id, tab = "base" } = useParams();
  const {
    loading,
    data = {},
    error,
    refetch,
  } = useN8n2("admin.training.details.v1", { id }, [id]);

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return <PageError />;
  }

  function submitForm(submission) {
    callN8n2("admin.training.update.v1", { id, ...submission })
      .then(() => {
        notification.success({
          description: "Данные успешно сохранены",
        });
        refetch();
      })
      .catch(() => {
        notification.error({
          message: "Ошибка",
          description: "Не удалось сохранить данные",
        });
      });
  }

  return (
    <>
      <TrainingEditHeader>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/trainings">Тренинги</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <HTML2Unicode>{_.get(data, "title")}</HTML2Unicode>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button
          type="primary"
          ghost={true}
          icon={<LinkOutlined />}
          href={`${getSiteUrl()}/trainings/${id}`}
          target="_blank"
        >
          Открыть тренинг
        </Button>
      </TrainingEditHeader>

      <Divider />

      <MyTabs
        activeKey={tab}
        resolvePath={(key) => `/trainings/${id}/${key}`}
        items={[
          {
            key: "base",
            title: "Основные поля",
            children: <TrainingBaseForm data={data} submitForm={submitForm} />,
          },
          {
            key: "lessons",
            title: "Уроки и сценарии",
            children: _.isBoolean(_.get(data, "dependent_content")) ? (
              <TrainingLessons id={id} />
            ) : (
              <Alert
                type="warning"
                message={
                  <Text>
                    Необходимо выбрать тип контента тренинга, чтобы управлять
                    содержанием
                  </Text>
                }
              />
            ),
          },
          {
            key: "scenarios",
            title: "Сценарии",
            children: <TrainingScenarios id={id} data={data} />,
          },
          {
            key: "streams",
            title: "Потоки",
            children: <TrainingStreams id={id} />,
          },
          {
            key: "offers",
            title: "Предложения",
            children: <TrainingOffers id={id} />,
          },
          {
            key: "description",
            title: "Описание",
            children: (
              <CardScreen
                dataFn="useN8n2"
                source="admin.screen.training_details_description.v1"
              />
            ),
          },
        ]}
      />
    </>
  );
}
